import emailjs from 'emailjs-com';
let btn_submit = document.getElementById('btn_submit');
let tip = document.getElementById('tip');
btn_submit.addEventListener('click', sendEmail);

function sendEmail() {
  tip.style.display = 'none';
  let user_name = document.getElementById('user_name').value;
  let user_tel = document.getElementById('user_tel').value;
  let user_amount = document.getElementById('user_amount').value;
  let user_category = document.getElementById('user_category').value;
  // console.log(user_amount,user_category)
  if (user_name !== '' && user_tel !== '') {
    emailjs.init('H-ExVKfpg3QmwOcLq');
    let templateParams = {
      user_name: user_name,
      user_tel: user_tel,
      user_amount: user_amount,
      user_category: user_category,
    };
    emailjs.send('service_vjkm1g5', 'template_0bimikq', templateParams).then(
      function (response) {
        //   console.log('SUCCESS!', response.status, response.text);
        btn_submit.disabled = true;
        btn_submit.style.backgroundColor = '#7a7a7a';
        btn_submit.textContent = '已送出';
      },
      function (error) {
        //   console.log('FAILED...', error);
        tip.style.display = 'block';
      }
    );
  } else {
    tip.style.display = 'block';
  }
}
