let preScrollpos = window.pageYOffset;
let preScrollposDesktop = window.innerHeight;
const navbarDivMobile = document.querySelector('#navbar_mobile');
const navbarDivDesktop = document.querySelector('#navbar_desktop');
const navbarBottomMobile =
  navbarDivMobile.offsetTop + navbarDivMobile.offsetHeight;
const innerHeight = window.innerHeight;
const innerWidth = window.innerWidth;
// console.log(innerHeight);
document.addEventListener('scroll', function (e) {
  if (innerWidth <= 576) {
    let currentScrollpos = window.pageYOffset;
    if (preScrollpos <= currentScrollpos) {
      navbarDivMobile.style.top = `-${navbarBottomMobile}px`;
    } else {
      navbarDivMobile.style.top = 0;
    }
    preScrollpos = currentScrollpos;
  } else {
    let currentScrollpos = window.pageYOffset;
    if (currentScrollpos >= innerHeight) {
      if (preScrollposDesktop <= currentScrollpos) {
        navbarDivDesktop.style.transform = 'translateY(-100%)';
      } else {
        navbarDivDesktop.style.transform = 'translateY(0%)';
      }
      preScrollposDesktop = currentScrollpos;
    } else {
      navbarDivDesktop.style.transform = 'translateY(-100%)';
    }
  }
});
